<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            :disabled="isCheckItemClass"
            codeGroupCd="RAM_CHECK_ITEM_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramCheckItemClassCd"
            label="LBLCLASSIFICATION"
            v-model="searchParam.ramCheckItemClassCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 평가대상 목록 -->
    <c-table
      ref="table"
      title="LBL0001091"
      :merge="grid.merge"
      :gridHeight="grid.height"
      :columns="grid.columns"
      :data="grid.data"
      rowKey="checkItemId"
      selection="multiple"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklistTargetPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        processCd: '',
        ramCheckItemClassCd: null,
      }),
    },
  },
  data() {
    return {
      searchParam: {
        processCd: '',
        ramCheckItemClassCd: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'ramCheckItemClassCd' },
          { index: 1, colName: 'processGroup' },
        ],
        columns: [
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            sortable: true,
            style: 'width:200px',
            type: 'link'
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            sortable: true,
            style: 'width:350px',
            type: 'html'
          },
          /**
           * 작업 : 작업구분, 작업유형, 작업형태
           * 기계기구 : 기계분류, 기계관리번호
           * 유해인자 : 분류, 취급부서
           */
        ],
        data: [],
        height: "600px",
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isCheckItemClass() {
      return Boolean(this.popupParam.ramCheckItemClassCd)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.checklist.target.base.url;
      // code setting
      if (this.popupParam.processCd) {
        this.searchParam.processCd = this.popupParam.processCd
      }
      if (this.popupParam.ramCheckItemClassCd) {
        this.searchParam.ramCheckItemClassCd = this.popupParam.ramCheckItemClassCd
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        // this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        // this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    select() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS",
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$emit("closePopup", selectData);
      }
    },
  }
};
</script>
